var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row flex"},[_vm._m(0),_c('div',{staticClass:"row-content p-t-10 font-14 font-400 text-333"},[_c('span',{staticClass:"label-btn pointer p-l-20 p-r-20 font-14 font-500 text-orange"},[_vm._v(_vm._s(this.isFootball ? '足球' : '篮球'))]),_c('div',{staticClass:"solution m-t-15 flex"},[_c('div',{staticClass:"left-team table-right-border flex align-center flex-column justify-center"},[_c('span',[_vm._v(_vm._s(_vm.matchInfo.leagueChsShort))]),_c('span',[_vm._v(_vm._s(_vm.matchInfo.matchTime))]),_c('div',{staticClass:"team w-100 flex align-center justify-center"},[_c('div',{staticClass:"home flex align-center"},[_c('span',{staticClass:"tag d-inline-block"},[_vm._v("[主]")]),_c('custom-span',{staticClass:"name",attrs:{"content":_vm.matchInfo.homeChs}})],1),_c('span',[_vm._v("vs")]),_c('div',{staticClass:"away flex align-center"},[_c('custom-span',{staticClass:"name",attrs:{"content":_vm.matchInfo.awayChs}}),_c('span',{staticClass:"tag d-inline-block"},[_vm._v("[客]")])],1)])]),_c('div',{staticClass:"right-rate flex-1 flex flex-column align-center"},[_c('span',{staticClass:"w-100 text-center d-inline-block table-bottom-border"},[_vm._v(_vm._s(_vm.playObj.label))]),_c('div',{staticClass:"flex w-100 text-center rate-list"},[_vm._l((_vm.playObj.keys),function(key,index){return [(key !== 'init_odds')?_c('span',{key:key,staticClass:"flex-1 pointer table-right-border",class:{
                      'is-active': index === _vm.rateIndex
                    }},[_c('custom-span',{attrs:{"content":`${_vm.playObj.labels[index]}${_vm.matchInfo[_vm.playObj.value][key]}`}})],1):_vm._e()]})],2)])])])]),_c('input-with-error',{key:_vm.form.title.updateKey,staticClass:"m-b-20 m-t-20",attrs:{"showLabel":"","label":_vm.form.title.label,"error-info":_vm.errorInfo.title,"row-info":_vm.form.title},on:{"update:rowInfo":function($event){return _vm.$set(_vm.form, "title", $event)},"update:row-info":function($event){return _vm.$set(_vm.form, "title", $event)},"validate":_vm.validateRow}}),_c('textarea-with-error',{key:_vm.form.description.updateKey,staticClass:"m-b-20 m-t-25",attrs:{"showLabel":"","label":_vm.form.description.label,"error-info":_vm.errorInfo.description,"row-info":_vm.form.description},on:{"update:rowInfo":function($event){return _vm.$set(_vm.form, "description", $event)},"update:row-info":function($event){return _vm.$set(_vm.form, "description", $event)},"validate":_vm.validateRow}}),_c('div',{staticClass:"row flex"},[_vm._m(1),_c('div',{staticClass:"price-content"},[_vm._l((_vm.prices),function(price){return [_c('span',{key:price.id,staticClass:"price-item p-l-10 p-r-10 pointer m-b-10",class:{
                  'm-r-10': price.value > 0,
                  'is-active': price.id === _vm.currentPrice
                },on:{"click":function($event){return _vm.chosePrice(price)}}},[_vm._v(_vm._s(price.value > 0 ? price.showValue : '免费'))])]})],2)]),_c('div',{staticClass:"row-outer flex p-t-30 p-l-30 m-b-20"},[_c('div',{staticClass:"save-button font-medium font-16 overflow-hidden"},[_c('ConfirmButton',{staticClass:"w-100 h-100 d-inline-block w-100 text-center font-medium font-16",attrs:{"title":"发布"},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label font-14 text-999 font-400"},[_c('span',[_vm._v("选择赛事")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label font-14 text-999 font-400"},[_c('span',[_vm._v("方案售价")])])
}]

export { render, staticRenderFns }