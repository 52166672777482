var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row bg-white font-14 text-999 font-400 text-center table-bottom-border"},[_c('div',{staticClass:"competition-title battle-title table-right-border"},[_vm._v(_vm._s(_vm.info.leagueChsShort))]),_c('div',{staticClass:"time competition-title table-right-border"},[_vm._v(_vm._s(_vm.info.time))]),_c('div',{staticClass:"battle-info battle-item table-right-border"},[_c('CustomSpan',{staticClass:"w-100 overflow-hidden text-ellipsis",attrs:{"content":_vm.info.battle}})],1),_vm._l((_vm.rows),function(row,index){return [_c('div',{key:row.key,staticClass:"battle-item",class:[
            `item${index+1}`,
            index === _vm.rows.length - 1 ? '' :  'table-right-border'
        ]},[_c('div',{staticClass:"ul flex align-center"},[_vm._l((row.keys),function(item,itemIndex){return [_c('div',{key:item,staticClass:"item pointer",class:[
                 itemIndex === row.keys.length -1 ? '' :  'table-right-border',
                 `${_vm.date.id}@${_vm.info.matchId}@${index+1}@${itemIndex+1}` === _vm.selectId ? 'is-active' : '',
                 row.value ? '' :'not-allowed',
                  index > 0 && itemIndex === 1 ? 'not-allowed' : ''
             ],on:{"click":function($event){return _vm.choseRate(row, row.key, item,index, itemIndex, `${_vm.date.id}@${_vm.info.matchId}@${index+1}@${itemIndex+1}`)}}},[_vm._v(_vm._s(row.value ? row.value[item] : '-')+" ")])]})],2)])]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }